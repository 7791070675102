/** @jsx jsx */
import { jsx } from "theme-ui"
import LiveChat from "react-livechat"
import { NavLinkList } from "./navigation"
import { ListLink } from "./list-link"
import { graphql, useStaticQuery } from "gatsby"
import { openChat } from "./live-chat-button"

const liveChatComponent = typeof window !== 'undefined' ? <LiveChat license={13878042} /> : null

const imagePath = (subPath) => `/assets/site/${subPath}`

const contactPhoneQuery = graphql`
  {
    contact: file(name: { eq: "contact-section" }) {
      props: childMarkdownRemark {
        fields: frontmatter {
          phone: call
        }
      }
    }
    companyInfo: file(name: { eq: "footer" }) {
      content: childMarkdownRemark {
        markdown: html
        fields: frontmatter {
          contactEmail
        }
      }
    }
  }
`

const Footer = () => {
  const {
    companyInfo: {
      content: {
        markdown,
        fields: {
          contactEmail
        }
      }
    },
    contact: {
      props: {
        fields: {
          phone
        }
      }
    }
  } = useStaticQuery(contactPhoneQuery)

  return <footer
    className="footer"
  >
    <div className="full-width">
      <div className="footer__grid">
        <div className="footer__contact">
          <div className="footer__logo">
            <picture>
              <img src={imagePath("logo-footer.png")} alt="Car Protect Direct" />
            </picture>
          </div>
          <ul>
            <li className="phone">
              <img src={imagePath("icons/phone.svg")} alt="phone" />
              <span><a href={`tel:${phone}`}>{phone}</a></span>
            </li>
            <li className="email">
              <img src={imagePath("icons/email.svg")} alt="email" />
              <span>
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </span>
            </li>
            <li className="chat">
              <img src={imagePath("icons/chat.svg")} alt="chat" />
              <span><a href="#chat" onClick={openChat}>Live chat</a></span>
            </li>
          </ul>
        </div>
        <div className="footer__links">
          <div className="footer__nav link-list">
            <ul>
              <NavLinkList />
              <ListLink to="/faq" children="FAQ" />
              <ListLink to="/privacy" children="Privacy policy" />
            </ul>
          </div>
          {/* <div className="footer__social link-list">
            <ul>
              <ListLink to="/??" children="Facebook" />
              <ListLink to="/??" children="Twitter" />
              <ListLink to="/??" children="Linkedin" />
              <ListLink to="/??" children="Instagram" />
            </ul>
          </div> */}
        </div>
        <div className="arrow-up">
          <a href="#top">
            <img src={imagePath('icons/arrow-up.svg')} alt="back to top" />
          </a>
        </div>
      </div>
      <hr />
      <div className="bottom-row">
        <div className="left-col" dangerouslySetInnerHTML={{ __html: markdown }}/>
        <div className="right-col">
          &copy; Car Protect Direct. All rights reserved
        </div>
      </div>
    </div>
    {liveChatComponent}
  </footer>
}

export default Footer
