/** @jsx jsx */
import { jsx } from "theme-ui"

const Header = ({ children }) => (
  <header className="site-header">
    {children}
  </header>
)

export default Header
