/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import { ListLink } from "./list-link"

const menuItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/warranty",
    title: "Warranty",
  },
  {
    path: "/service",
    title: "Service",
  },
  {
    path: "/assistance",
    title: "Assistance",
  },
  {
    path: "/carefree",
    title: "Carefree",
  },
  {
    path: "/about",
    title: "About",
  },
  {
    path: "/contact",
    title: "Contact",
  },
]



export const NavLinkList = () => {
  const listMenuItems = menuItems.map((menuItem, index) => (
    <ListLink key={index} to={menuItem.path}>
      {menuItem.title}
    </ListLink>
  ))

  return <>
    {listMenuItems}
  </>
}

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const isActiveClass = this.state.showMenu ? " is-active" : ""

    return (
      <nav className="site-navigation">
        <button
          onClick={this.handleToggleClick}
          className={`menu-trigger${isActiveClass}`}
        >
          <div className="icon-menu-line">
            <RiMenu3Line />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button>
        <ul>
          <NavLinkList/>
        </ul>
      </nav>
    )
  }
}

export default Navigation
