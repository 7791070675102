/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Logo from "./logo"
import Navigation from "./navigation"

import "../assets/scss/style.scss"
import Footer from "./footer"

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
    topBanner: file(name: { eq: "top-banner" }) {
      props: childMarkdownRemark {
        bannerContent: html
      }
    }
  }
`

const Layout = ({ children, className }) => {
  const { site: { siteMetadata: { siteTitle } }, topBanner } = useStaticQuery(query)
  const { props: { bannerContent } } = topBanner

  return (
    <div className={className}>
      <div className="home-banner" dangerouslySetInnerHTML={{ __html: bannerContent }}></div>
      <div className="full-width">
        <Header>
          <Logo title={siteTitle} />
          <div sx={layoutStyle.nav}>
            <Navigation />
          </div>
        </Header>
        <main className="container">{children}</main>
      </div>
      <Footer/>
    </div>
  )
}

export default Layout

const layoutStyle = {
  appearance: {
    display: ["none", "none", "none", "flex"],
    alignItems: "center",
    gap: 4,
  },
  nav: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
}
