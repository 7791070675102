import React from "react"

export const openChat = (e) => {
  e.preventDefault()
  if (typeof window !== 'undefined' && typeof window.LC_API !== 'undefined') {
    window.LC_API.open_chat_window()
  }
}

export const LiveChatButton = ({ className = '', label }) => <button
    className={`button ${className}`}
    onClick={openChat}
  >
  {label}
</button>
